@import '@/styles/_global';
.swiper {
  padding-bottom: 0;
}

@include desktop() {
  .root {
    overflow-x: auto;
    cursor: ew-resize;
    display: flex;
    gap: toRemDesktop(40);
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

@media (min-width: 1024px) {
  .root {
    padding-left: calc(3vw);
    // padding-left: calc(50vw - 512px + 2.5rem);
  }
}
@media (min-width: 1920px) {
  .root {
    padding-left: calc(50vw - 960px + 7rem);
    padding-right: calc(50vw - 960px + 7rem);
  }
}

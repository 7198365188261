@import '@/styles/_global';
.root {
  overflow: hidden;
}

.content {
  min-height: toRem(365);
  padding: toRem(16) toRem(18);
  position: relative;
  border-radius: toRem(16);
  overflow: hidden;

  &::before,
  & > .arrow, img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.gradient {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    z-index: 2;
    background: linear-gradient(180deg, rgba(26, 50, 93, 0) 0%, #1a325d 96.05%);
  }
}

.strip {
  position: absolute;
  bottom: toRem(23);
  left: 0;
  z-index: 3;
  // width: fit-content;
  height: toRem(34);
  clip-path: polygon(100% 0%, 92% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  background: #fff;
  color: var(--primary-blue);
  padding: toRem(12) toRem(30) toRem(8) toRem(18);
  margin: auto;
  font-family: var(--font-raleway);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 700;
  line-height: toRem(13); /* 77.313% */
  letter-spacing: toRem(2);
  text-transform: uppercase;
  white-space: nowrap;
}

.heading {
  font-variant-numeric: lining-nums proportional-nums;
  font-size: toRem(16);
  letter-spacing: toRem(0.32);
  margin-bottom: toRem(12);
  color: var(--P-Blue, #1a325d);
}
.text {
  font-size: toRem(14);
  line-height: toRem(18.536);
  letter-spacing: toRem(0.42);
  max-width: 80%;
  color: var(--P-Blue, #1a325d);
}
.bottom {
  min-height: toRem(74);
  padding: toRem(16) toRem(19);
  position: relative;

  .arrow {
    position: absolute;
    right: toRem(18);
    bottom: toRem(16);
    height: toRem(42);
    width: toRem(42);
  }
}
.blogArrow{
  background-position: -12px -184px;
  background-size: 60px;
}
.clockIcon{
  width: 20px;
  height: 20px;
  background-position: -28px -49px;
}

@media (min-width: 1199px) {
  .blogArrow{
    background-position: -12px -184px;
    background-size: 60px;
  }
}


@media (min-width: 1600px) {
  .blogArrow{
    background-position: -14px -222px;
    background-size: inherit;
  }
}

@media (min-width: 1799px) {
  .blogArrow{
    background-position: -18px -275px;
    background-size: 90px;
  }
}

@include desktop() {
  .root {
    overflow: hidden;
    overflow: hidden;
    width: toRemDesktop(486);
    min-width: toRemDesktop(486);
  }
  .content {
    min-height: toRemDesktop(435);
    padding: toRemDesktop(60) toRemDesktop(24);
    border-radius: toRemDesktop(20);
  }
  .gradient {
    &::after {
      height: 48%;
    }
  }
  .strip {
    font-size: toRemDesktop(20);
    line-height: toRemDesktop(16); /* 80% */
    letter-spacing: toRemDesktop(2);
  }
  .heading {
    font-size: toRemDesktop(24);
    margin-top: toRemDesktop(0.48);
    margin-bottom: toRemDesktop(13);
  }
  .text {
    font-size: toRemDesktop(20);
    line-height: toRemDesktop(26.48);
    letter-spacing: toRemDesktop(0.6);
  }
  .bottom {
    min-height: toRemDesktop(101);
    padding: toRemDesktop(32) toRemDesktop(26) toRemDesktop(26) toRemDesktop(30);
    .arrow {
      right: toRemDesktop(32);
      bottom: toRemDesktop(25);
      height: toRemDesktop(54);
      width: toRemDesktop(54);
    }
  }
}
